.footer {
    width: 100%;
    background-color: #f3f3f3;  /* 배경색 */
    color: #333;                /* 글자색 */
    text-align: center;
    padding: 0px 0;             /* 상하 패딩 */
    bottom: 0;                  /* 하단에 위치 */
    left: 0;
  }
  
  .footer-content p {
    margin: 0;                  /* 마진 제거 */
    font-size: 10px;            /* 작은 글씨 크기 */
  }
  